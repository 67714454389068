import React from 'react';
import { bool } from 'prop-types';

import LongFilterPlain from './LongFilterPlain';

const LongFilter = props => {
  const { showAsPopup, marketplaceCurrency, ...rest } = props;
  return <LongFilterPlain showAsPopup={showAsPopup} marketplaceCurrency={marketplaceCurrency} {...rest} />
};

export default LongFilter;
