import React from 'react';
import { bool, func, number, object, string } from 'prop-types';
import classNames from 'classnames';
import debounce from 'lodash/debounce';
import { Field, Form as FinalForm, FormSpy } from 'react-final-form';

import { FormattedMessage, injectIntl, intlShape } from '../../../util/reactIntl';

import { Form, RangeSlider } from '../../../components';

import css from './LongFilterForm.module.css';

const DEBOUNCE_WAIT_TIME = 400;

// Helper function to parse value for min handle
// Value needs to be between slider's minimum value and current maximum value
const parseMin = (min, currentMax) => value => {
  const parsedValue = Number.parseInt(value, 10);
  if (isNaN(parsedValue)) {
    return '';
  }
  return parsedValue < min ? min : parsedValue > currentMax ? currentMax : parsedValue;
};

// Helper function to parse value for max handle
// Value needs to be between slider's max value and current minimum value
const parseMax = (max, currentMin) => value => {
  const parsedValue = Number.parseInt(value, 10);
  if (isNaN(parsedValue)) {
    return '';
  }
  return parsedValue < currentMin ? currentMin : parsedValue > max ? max : parsedValue;
};

// LongFilterForm component
const LongFilterFormComponent = props => {
  const { liveEdit, onChange, onSubmit, onCancel, onClear, ...rest } = props;

  if (liveEdit && !onChange) {
    throw new Error('LongFilterForm: if liveEdit is true you need to provide onChange function');
  }

  if (!liveEdit && !(onCancel && onClear && onSubmit)) {
    throw new Error(
      'LongFilterForm: if liveEdit is false you need to provide onCancel, onClear, and onSubmit functions'
    );
  }

  const handleChange = debounce(
    formState => {
      if (formState.dirty) {
        const { min, max, ...restValues } = formState.values;
        onChange({
          min: min === '' ? rest.min : min,
          max: max === '' ? rest.max : max,
          ...restValues,
        });
      }
    },
    DEBOUNCE_WAIT_TIME,
    { leading: false, trailing: true }
  );

  const handleSubmit = values => {
    const { min, max, ...restValues } = values;
    return onSubmit({
      min: min === '' ? rest.min : min,
      max: max === '' ? rest.max : max,
      ...restValues,
    });
  };

  const formCallbacks = liveEdit
    ? { onSubmit: () => null }
    : { onSubmit: handleSubmit, onCancel, onClear };
  return (
    <FinalForm
      {...rest}
      {...formCallbacks}
      render={formRenderProps => {
        const {
          form,
          handleSubmit,
          id,
          showAsPopup,
          onClear,
          onCancel,
          isOpen,
          isInSideBar,
          contentRef,
          style,
          intl,
          values,
          minLimit,
          maxLimit,
          step,
          children,
        } = formRenderProps;
        const { min: minRaw, max: maxRaw } = values;
        const min = typeof minRaw !== 'string' ? minRaw : min;
        const max = typeof maxRaw !== 'string' ? maxRaw : max;

        const handleCancel = () => {
          // reset the final form to initialValues
          form.reset();
          onCancel();
        };

        const clear = intl.formatMessage({ id: 'LongFilterForm.clear' });
        const cancel = intl.formatMessage({ id: 'LongFilterForm.cancel' });
        const submit = intl.formatMessage({ id: 'LongFilterForm.submit' });

        const classes = classNames(css.root, {
          [css.popup]: showAsPopup,
          [css.isOpenAsPopup]: showAsPopup && isOpen,
          [css.plain]: !showAsPopup,
          [css.isOpen]: !showAsPopup && isOpen,
        });

        return (
          <Form
            className={classes}
            onSubmit={handleSubmit}
            tabIndex="0"
            contentRef={contentRef}
            style={{ minWidth: '300px', ...style }}
          >
            <div className={css.contentWrapper}>
              {showAsPopup ? (
                <span className={css.label}>
                  <FormattedMessage id="LongFilterForm.label" />
                </span>
              ) : null}
              <div className={css.inputsWrapper}>
                <Field
                  className={classNames(css.min, { [css.sidebar]: isInSideBar })}
                  id={`${id}.min`}
                  name="min"
                  component="input"
                  type="number"
                  placeholder={minLimit}
                  min={minLimit}
                  max={maxLimit}
                  step={step}
                  parse={parseMin(minLimit, max)}
                />
                <span className={css.separator}>-</span>
                <Field
                  className={classNames(css.max, { [css.sidebar]: isInSideBar })}
                  id={`${id}.max`}
                  name="max"
                  component="input"
                  type="number"
                  placeholder={maxLimit}
                  min={minLimit}
                  max={maxLimit}
                  step={step}
                  parse={parseMax(maxLimit, min)}
                />
              </div>
            </div>

            <div className={css.sliderWrapper}>
              <RangeSlider
                min={minLimit}
                max={maxLimit}
                step={step}
                handles={[min, max]}
                onChange={handles => {
                  form.change('min', handles[0]);
                  form.change('max', handles[1]);
                }}
              />
            </div>

            {liveEdit ? (
              <FormSpy onChange={handleChange} subscription={{ values: true, dirty: true }} />
            ) : (
              <div className={css.buttonsWrapper}>
                <button className={css.clearButton} type="button" onClick={onClear}>
                  {clear}
                </button>
                <button className={css.cancelButton} type="button" onClick={handleCancel}>
                  {cancel}
                </button>
                <button className={css.submitButton} type="submit">
                  {submit}
                </button>
              </div>
            )}
            {children}
          </Form>
        );
      }}
    />
  );
};

const LongFilterForm = injectIntl(LongFilterFormComponent);

export default LongFilterForm;
